import { BrowserRouter } from "react-router-dom"; 
import Layout from "./components/layout/Layout";
import './assets/styles/main.css';
import AnimationRout from "./components/routes/AnimationRout";

const App = () => {
  return (
      <BrowserRouter>
        <Layout>
          <AnimationRout/> 
        </Layout>
      </BrowserRouter>
  );
} 

export default App;
 