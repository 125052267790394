import menu from './../../../assets/icon/Menu.svg';
// import logo from './../../../assets/images/ag-logo.png';
import logo from './../../../assets/images/logoRed.png';
import { useState } from "react";
import Search from './../../filter/Search';
import { Link } from 'react-router-dom';
import Menu from '../menu/Menu';
import ContactUs from '../ContactUs';
const Header = () => {
    // const [routPage] = useState(30);
    const [isShow, setIsShow] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [show,setShow]=useState(false);
    const showSocial =()=>{
        setShow(true)
        setIsShow(false)
    }
    const hideMenu=()=>{
        setIsShow(false)
    }
    const searching=()=>{
        setIsShow(false);
        setShowSearch(true)
    }
    const hideSearch=()=>{
        setShowSearch(false)
    }
    const clickHome=()=>{
        setIsShow(false)
    }
    return (
        <header className="d-flex justify-content-between">
           
            <div className="w-50 d-flex justify-content-between align-items-center">
                <img src={menu} alt="menu" onClick={()=>setIsShow(!isShow)} />
                {isShow&&<Menu hideMenu={hideMenu} searching={searching}  clickHome={()=>clickHome()} showSocial={()=>showSocial()}/>}
                {showSearch&&<Search hideSearch={hideSearch}/>}
                {show&&<ContactUs onClick={()=>setShow(false)}/>}
            </div>
            <div className="w-50 text-left">
                <div className='d-flex w-25 h-100 f-left justify-content-center align-items-center pl-1'>
                    <Link to="/" className='d-flex w-25 h-100 f-left justify-content-center align-items-center pl-1'> <img src={logo} alt="logo" /></Link>
                </div>
            </div>
        </header>
    );
}

export default Header;