import Footer from "../shareComponent/footer/Footer";
import Header from "../shareComponent/header/Header";
import { useState } from "react";
 const Layout = ({ children }) => {
    const [open,setOpen]=useState(false)
    return (
        <> 
            <Header onclick={()=>setOpen(!open)}/>
                <main className='contentAll'>
                    {children}
                </main>
        </>
    );
}

export default Layout;