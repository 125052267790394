import logoRed from './../../assets/images/logoRed.png'
const MoreAbout = ({ onclick }) => {
    return (
        <>
            <div className="popUpMedia" onClick={onclick}></div>
            <div className="contentMoreAbout">
                <div className="framBox">
                    <span className="dotRight"></span>
                    <span className="dotLeft"></span>
                    <span className="imgHeadBox"><img src={logoRed}/></span>
                    <div className="titleAbout">مجموعه کارآفرینی و کسب و کارهای نوین ، صنایع تبدیلی کشاورزی ، صنایع غذایی و بهداشتی و صنایع دارویی AG</div>
                    <div className="descriptionAbout">اهداف ما برای کمک و یاری به آنها در بلند مدت و کوتاه مدت خود اشتغالی در اشل خانگی ، دانشجویی مغازه ای و کارگاهی و در نهایت کارخانه ای متوسط و بزرگ می باشداهداف ما برای کمک و یاری به آنها در بلند مدت و کوتاه مدت خود اشتغالی در اشل خانگی ، دانشجویی ، مغازه ای و کارگاهی و در نهایت کارخانه ای متوسط و بزرگ می باشد </div>

                </div>
            </div>
        </>
    );
}

export default MoreAbout;