import axios from "axios";
import { useEffect, useState } from "react";
import SearchList from "./SearchList";
import {BsXLg} from "react-icons/bs";
const Search = ({ hideSearch }) => {
    const [product, setproduct] = useState([]);
    const [search, setSearch] = useState([]);
    const [show,setShow]=useState(false)
    const [pro,setPro]=useState([])
    useEffect(() => {
        axios.get('https://ag777.com/api/products/get?token=QSBiJF6Rk%26F5%262yLk1%qz^2*Up').then((res) => {
            setproduct(res.data)
            localStorage.setItem('search', JSON.stringify(res.data))
        }).catch((err) => {
            console.log(err)
            setproduct(JSON.parse(localStorage.getItem('search')));
        })
    }, [])
    const Searching = (e) => {
        const value = e.target.value;
        if (value == "") {
            setSearch([])
        }
        else{
            const newSearch = product.filter((p) => p.name.includes(value))
            setSearch(newSearch)
        }
     
    }
    function removeTags(str = '') {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        return str.replace(/(<([^>]+)>)/ig, '').replace(/(&nbsp;)*/g, '').replace(/(&;)*/g, '').replace(/(&zwnj;)*/g, '').replace(/(&ndash;)*/g, '').replace(/(&middot;)*/g, '');
    }
    const onClick=(pro)=>{
        setPro(pro);
        setShow(true);
        axios.get(`https://ag777.com/api/catalog/products/get?token=QSBiJF6Rk%26F5%262yLk1%qz^2*Up&id=${pro.id}`).then((res) => {
            setPro(res.data)
            localStorage.setItem('detailes'+pro.id, JSON.stringify(res.data));
        }).catch((err) => {
            console.log(err)
            setPro(JSON.parse(localStorage.getItem('detailes'+pro.id)));
        })
    }
 
return (
    <div className="searchBox">
        <div className="popUpMenu" onClick={hideSearch}> </div>
        <div className="boxSearching"><BsXLg onClick={hideSearch} className="close-btn-search"/>
            <input type="text" className="inputSearch" placeholder="جستجوی محصول مورد نظر..." onChange={(e) => Searching(e)} />
            <div className="boxResualtSearch">
                 {search.length==0&&<div className="center-center">محصول مورد نظر را پیدا کنید</div>}
                {search.map((pro) => {
                    return <SearchList key={pro.id} onClick={()=>onClick(pro)} name={pro.name} image={pro.image} id={pro.id} />
                })}
                {show&&
                    <div>
                        <div className="popUpMenu" onClick={() => setShow(false)}> </div>
                        <div className="detailesProduct">
                            <div className="framwork">
                                <p className="titleDetailes">{pro.title}</p>
                                <div className=' d-flex'>
                                    <img  className='detailesImage' src={`https://ag777.com/images/products/${pro.image}`} alt={pro.image_alt} />
                                </div>
                                <p className='paragraphDetailes'>{removeTags(pro.description)}</p>
                                <p className='price'>قیمت:   {pro.price}</p>
                            </div>
                        </div>
                    </div>
                    
                
                }
            </div>

        </div>

    </div>
);
}

export default Search;