import { Link, useParams, useNavigate } from 'react-router-dom';
import logo from './../../assets/images/logoRed.png';
import leftIcon from './../../assets/icon/arrow-left.svg';
import { useState, useEffect } from 'react';
import playVideo from './../../assets/images/playVideo.png'
import axios from 'axios';
import Spinner from '../spiner/Spinner';
const DetailesProdct = () => {
    const params = useParams()
    const navigate = useNavigate();
    const idPro = params.id;
    const [categury, setCategury] = useState([]);
    const [toglle, setToglle] = useState(false);
    const [titleProd, setitleProd] = useState([]);
    const [newData, setnewData] = useState([]);
    const [idProduct, setIdProduct] = useState([]);
    const [showImg, setShowImg] = useState(false);
    const [showVideo,setShowVideo]=useState(false)
    const [load, setLoad] = useState(false)


    function removeTags(str = '') {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        return str.replace(/(<([^>]+)>)/ig, '').replace(/(&nbsp;)*/g, '').replace(/(&;)*/g, '').replace(/(&zwnj;)*/g, '').replace(/(&ndash;)*/g, '').replace(/(&middot;)*/g, '');
    }

    useEffect(() => {
        setLoad(false)
        axios.get(`https://ag777.com/api/catalog/products/get?token=QSBiJF6Rk%26F5%262yLk1%qz^2*Up&category=${idPro}`).then((res) => {
            setCategury(res.data)
            localStorage.setItem('singleDetailes'+idPro, JSON.stringify(res.data))
            setLoad(true)
        }).catch((err) => {
            console.log(err)
            setCategury(JSON.parse(localStorage.getItem('singleDetailes'+idPro)));
        })
        axios.get(`https://ag777.com/api/catalog/category/get?token=QSBiJF6Rk%26F5%262yLk1%qz^2*Up&id=${idPro}`).then((res) => {
            setLoad(false)
            setitleProd(res.data)
            localStorage.setItem('singleDetaile'+idPro, JSON.stringify(res.data))
            setLoad(true)
        }).catch((err) => {
            console.log(err)
            setitleProd(JSON.parse(localStorage.getItem('singleDetaile'+idPro)));
        })

    }, [])
    useEffect(() => {
        if (typeof (idProduct) == 'number') {
            axios.get(`https://ag777.com/api/catalog/products/get?token=QSBiJF6Rk%26F5%262yLk1%qz^2*Up&id=${idProduct}`).then((res) => {
                setnewData(res.data)
                localStorage.setItem('detailes'+idProduct, JSON.stringify(res.data));
                 
            }).catch((err) => {
                console.log(err)
                setnewData(JSON.parse(localStorage.getItem('detailes'+idProduct)));
            })
        }
    }, [idProduct])
    const showDetaile = (id) => {
        setToglle(true)
        setIdProduct(id)
    }
    if (!load) return <Spinner />
    return (
        
        <div className="position-relative h-desktop-100 w-100">
            {
                <div className={toglle ? 'd-block' : 'd-none'}>
                    <div className="popUpMenu" onClick={() => setToglle(false)}> </div>
                    <div className="detailesProduct">
                        <div className="framwork">
                            <p className="titleDetailes">{newData.title}</p>
                            <div className=' d-flex'>
                                 <img onClick={() => setShowVideo(true)} className='detailesImage' src={playVideo} alt="product" />
                                 <img onClick={() => setShowImg(true)} className='detailesImage' src={`https://ag777.com/images/products/${newData.image}`} alt="product" />
                            </div>
                            <p className='paragraphDetailes'>{removeTags(newData.description)}</p>
                            <div className='d-flex w-100 justify-content-between'>
                                <p>قیمت:   {removeTags(newData.price)}</p>
                                <p>قیمت با تخفیف: {newData.price_discount}</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {showImg && <span className='popUpImag' onClick={() => setShowImg(false)}><img className='openImages' src={`https://ag777.com/images/products/${newData.image}`} alt="product" /></span>}
            {showVideo && <span className='popUpImag' onClick={() => setShowVideo(false)}><video width="360px" height="fit-content" controls poster="https://ag777.com/img/AGRed.png" onClick={() => setShowVideo(true)} ><source src={`${titleProd.video}`} type="video/mp4" /> </video></span>}
             
           {console.log(titleProd)}
            <div className="unitTitleProduct">
                <div className="contentTitleProduct">
                    <span className='titleCategury'>{titleProd.title}</span>
                </div>
                <div className='groupingProduct'>
                    {categury.map((product) => {
                        return <Link key={product.id} id={product.id} className="itemProduct" onClick={() => showDetaile(product.id)} >
                            <img className="productImage" src={`https://ag777.com/images/products/${product.image}`} alt="iconProduct" />
                            <span>{product.title}</span>
                        </Link>
                    })}
                </div>
                <div className="nameCopmpony">
                    <img src={logo} />
                    <span className=''>شرکت کارآفرینی ای جی</span>
                </div>
            </div>
            <div className='nextPrevItemArrow' >
                <Link className='nextBtn'>
                </Link>
                <Link className='prevBtn' onClick={() => navigate(-1)}>
                    <img src={leftIcon} alt="leftIcon" />
                </Link>
            </div>
        </div>
    );
}

export default DetailesProdct;