import productBaner from './../../assets/images/productImages.png';
import rightIcon from './../../assets/icon/arrow-right.svg';
import { Link } from 'react-router-dom';
import Footer from './footer/Footer';
import Media from './socialMedia/Media';

const Index = () => {
    return (
            <>
                 <div className='position-relative'>
                    <img className='w-100' src={productBaner} alt="productBaner" />

                    <div className='nextPrevItemArrow' >
                        <Link className='nextBtn' to="/video">
                            <img src={rightIcon} alt="rightIcon" />
                        </Link>
                    </div>


                </div>
                <Footer>
                    <Media />
                </Footer>
            </>
    );
}

export default Index;