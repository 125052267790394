import { Link } from 'react-router-dom';
import leftIcon from './../../assets/icon/arrow-left.svg';
import rightIcon from './../../assets/icon/arrow-right.svg';
import Media from '../shareComponent/socialMedia/Media';
import employe from './../../assets/images/picEmploye.jpg';
import logoRed from './../../assets/images/logoRed.png';
import { useState } from 'react';
import MoreAbout from './MoreAbout';
import Footer from '../shareComponent/footer/Footer';

const AboutUs = () => {
    const [seeMore, setSeeMore] = useState(false)
    return (
        <>
            <div className='position-relative w-100'>
                {seeMore ? <MoreAbout onclick={() => setSeeMore(false)} /> : ''}
                <div className='contentChildAbout gap-1'>
                    <div className='d-flex flex-direction-col align-items-center gap-1'>
                        <p className='w-60 f-10'>
                            رسالت این مجموعه در راستای فقر زدایی اجتماعی ، توسعه تولید محصوالت کشاورزی و دامی و کمک به افراد یست که جویای کار هستند و هنوز دانش آموخته های خود را به هر دلیلی
                        </p>
                        <div>
                            <img src={employe} alt="employe" />
                        </div>
                    </div>
                    <div className='nameComponyAbout aboutUSCompony'>
                        <div className='d-flex align-items-center '>
                            <img className='w-100' src={logoRed} alt="logoRed" />
                            <p className='f-10'>شرکت کارآفرینی ای جی</p>
                        </div>
                        <span className='rotate-45' onClick={() => setSeeMore(!seeMore)}> <span className='text-rotate-45'> بیشتر </span> </span>

                    </div>
                </div>
                <div className='nextPrevItemArrow' >
                    <Link className='nextBtn' to="/categury/">
                        <img src={rightIcon} alt="rightIcon" />
                    </Link>
                    <Link className='prevBtn' to="/video">
                        <img src={leftIcon} alt="leftIcon" />
                    </Link>
                </div>
            </div>
            <Footer/>
        </>

    );
}

export default AboutUs;