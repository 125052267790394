import { Link } from 'react-router-dom';
import aparat from './../../assets/images/aparat.png';
import jabe from './../../assets/images/jabe.png';
import instagram from './../../assets/images/instagram.png';
import youtube from './../../assets/images/youtube.png';
import tamasha from './../../assets/images/tamasha.png';
import tell from './../../assets/images/tell.png';
const ContactUs = ({ onClick }) => {
    return (
        <div>
            <div className="popUpMedia" onClick={onClick}> </div>
            <div className="mediaPopUp">
                <Link to="https://instagram.com/ag.karafarini" className='linkSocial'>
                    <img src={instagram} alt="instagram" />
                </Link>
                <Link to="https://namasha.com/ag777" className='linkSocial'>
                    <img src={tamasha} alt="tamasha" />
                </Link>
                <Link to="https://aparat.com/v/Gn0JT" className='linkSocial'>
                    <img src={aparat} alt="aparat" />
                </Link>
 
                <Link to="https://youtube.com/@agkarafarini3978" className='linkSocial'>
                    <img src={youtube} alt="youtube" />
                </Link>
                
                <Link to="https://jabeh.com/c/1njZZl" className='linkSocial'>
                    <img src={jabe} alt="jabe" />
                </Link>
                <Link to="tel:02146096724" className='linkSocial'>
                    <img src={tell} alt="tell" />
                </Link>
               
            </div>
        </div>
    );
}

export default ContactUs