import { Link } from 'react-router-dom';
import Location from './../../assets/icon/location.svg';
const SharedMedia = ({ onClick }) => {
    return (
        <div>
            <div className="popUpMedia" onClick={onClick}> </div>
            <div className="shareMedia">
                <div className="titleCallUs">تماس با ما</div>
                <div className='boxAddress'>
                    <div className='d-flex align-items-center'>
                        <img src={Location} alt="Location" />
                        <span className='font-bold'>شعبه تهران</span>
                    </div>
                    <div>
                        ﺻﺎدﻗﯿﻪ ،ﺑﻠﻮار آﯾﺖ اﻟﻠﻪ ﮐﺎﺷﺎﻧﯽ رو ﺑﻪ روی ﻣﺴﺠﺪ ﻧﻈﺎم ﻣﺎﻓﯽ، ﺳﺎﺧﺘﻤﺎن اﺗﺮک
                    </div>
                </div>
                <div className='d-flex flex-direction-col box-link'>
                    <Link to="tel:02146096724" className='linkBtn'>تماس با ما</Link>
                    <Link to="https://ag777.com/"  target="_blank" className='linkBtn'> website</Link>
                </div>
                <div className='mapBox'>
                    <iframe width="95%" height="200" src="https://map.ir/lat/35.740795/lng/51.301898/z/17/p/AG777"></iframe>
                </div>
            </div>
        </div>
    );
}

export default SharedMedia;