import { Link, useParams, useNavigate } from 'react-router-dom';
import logo from './../../assets/images/logoRed.png';
import leftIcon from './../../assets/icon/arrow-left.svg';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Spinner from '../spiner/Spinner';
const Product = () => {
    const navigate = useNavigate();
    const params = useParams()

    const idPro = params.id;
    const [categury, setCategury] = useState([]);
    const [toglle, setToglle] = useState(false);
    const [newData, setnewData] = useState([]);
    const [titleProd, setitleProd] = useState([]);
    const [load, setLoad] = useState(false)

    useEffect(() => {
        setLoad(false)
        axios.get(`https://ag777.com/api/catalog/category/get?token=QSBiJF6Rk%26F5%262yLk1%qz^2*Up&parent=${idPro}`).then((res) => {
            setCategury(res.data)
            localStorage.setItem('productsParent' + idPro, JSON.stringify(res.data))
            setLoad(true)
        }).catch((err) => {
            console.log(err)
            setCategury(JSON.parse(localStorage.getItem('productsParent' + idPro)));
        })
        axios.get(`https://ag777.com/api/catalog/category/get?token=QSBiJF6Rk%26F5%262yLk1%qz^2*Up&id=${idPro}`).then((res) => {
            setLoad(false)  
            setitleProd(res.data)
            localStorage.setItem('productParent' + idPro, JSON.stringify(res.data))
            setLoad(true)
        }).catch((err) => {
            console.log(err)
            setitleProd(JSON.parse(localStorage.getItem('productParent' + idPro)));
        })
    }, [])


    function removeTags(str = '') {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        return str.replace(/(<([^>]+)>)/ig, '').replace(/(&nbsp;)*/g, '').replace(/(&;)*/g, '').replace(/(&zwnj;)*/g, '').replace(/(&ndash;)*/g, '').replace(/(&middot;)*/g, '');
    }
    if (!load) return <Spinner />

    return (

        <div className="position-relative w-100 h-desktop-100">

            <div className="unitTitleProduct">
                <div className="contentTitleProduct">
                    <span className='titleCategury'>{titleProd.title}</span>
                </div>
                <div className='groupingProduct'>
                    {categury.map((product) => {
                        return <Link key={product.id} to={`/categury/product/products/DetailesProdct/${product.id} `} id={product.id} className="itemProduct"  >
                            <img className="productImage" src={`https://ag777.com/uploads/${product.image}`} alt="iconProduct" />
                            <span>{product.title}</span>
                        </Link>
                    })}
                </div>
            </div>
            <div className='nextPrevItemArrow' >
                <Link className='nextBtn'>
                </Link>
                <Link className='prevBtn' onClick={() => navigate(-1)}>
                    <img src={leftIcon} alt="leftIcon" />
                </Link>
            </div>
        </div>
    );
}

export default Product;