import { Link } from 'react-router-dom';
import instagram from './../../../assets/images/instagram-b.png';
import namasha from './../../../assets/images/namasha-b.png';
import aparat from './../../../assets/images/aparat-b.png';
import youtube from './../../../assets/images/youtube-b.png';
import jabe from './../../../assets/images/jabe-b.png';
import logoRed from './../../../assets/images/logoRed.png';

const Media = () => {
    return (
    <div className='media'>
        <div className='nameCompony'>
            <img className='w-100' src={logoRed} alt="logoRed" />
            <p>شرکت کارآفرینی ای جی</p>
        </div>
        <div className='d-flex justify-content-between'>
            <Link to="https://instagram.com/ag.karafarini">
                <img src={instagram} alt="instagram" />
            </Link>
            <Link to="https://namasha.com/ag777">
                <img src={namasha} alt="namasha" />
            </Link>
            <Link to="https://aparat.com/v/Gn0JT">
                <img src={aparat} alt="aparat" />
            </Link>
            <Link to="https://youtube.com/@agkarafarini3978">
                <img src={youtube} alt="youtube" />
            </Link>
            <Link to="https://jabeh.com/c/1njZZl">
                <img src={jabe} alt="jabe" />
            </Link>
        </div>
    </div>

    );
}

export default Media;